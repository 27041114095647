import { isMobile } from "../utils/index";
export default {
    address: "桃園市中壢區高鐵南路二段289號 旁",
    googleSrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7231.877561788431!2d121.20011303247057!3d25.002196083987208!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe6792ec242dba17b!2zMjXCsDAwJzA3LjkiTiAxMjHCsDEyJzE2LjIiRQ!5e0!3m2!1szh-TW!2stw!4v1638928556319!5m2!1szh-TW!2stw",
    googleLink: "https://goo.gl/maps/em1y7oY2aKMGEcYS8",
    phone: "03-3278888",
    fbLink: "https://www.facebook.com/102662232265645",
    fbMessage: "https://m.me/102662232265645",
    caseName: "青茵悅",
    indigatorLength: 10,
    houseInfos: [
        ["投資興建", "昆旺建設"],
        ["建照號碼", "109 桃市都建執照字第會壢 01417-01 號"],
        ["工程營造", "逢成營造"],
        ["廣告銷售", "優越佳廣告"],
        ["接待會館", "桃園市中壢區高鐵南路二段289號 旁"],
        ["規劃坪數", "18-36坪"],
        ["建築規劃", "林宗良建築師事務所"],
    ],

    gtmCode: [""], // 可放置多個
    recaptcha_site_key_v2: "6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz",
    recaptcha_site_key: "6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s", // recaptcha v3
    recaptcha_user_token: "6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa",
    order: {
        title: "立即預約",
        subTitle: isMobile ?
            "若想了解更多資訊，歡迎填寫表單或來電洽詢<br>將由專人為您服務， 謝謝！ " :
            "若想了解更多資訊，歡迎填寫表單或來電洽詢，將由專人為您服務， 謝謝！ ",
    },
};